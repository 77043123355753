import { MainMenu } from "./header/MainMenu";
import { Footer } from "./Footer";
import { OverviewLanding } from "Components/Feature/Overview/OverviewLanding";

export const Content = () => {
  return (
    <>
      <MainMenu />
      <OverviewLanding />
      <Footer />
    </>
  );
};
