import * as React from "react";

import "assets/css/main.css";

var __html = require('./index.js');
var template = { __html: __html };

export const OverviewLanding = () => {
  return (
    <main className="docs-lp">
      <div className="docs-lp">
        <span dangerouslySetInnerHTML={template} />
      </div>
    </main>
    );
  }
