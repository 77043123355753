import { useRef, useState } from "react";
import { ReactComponent as SearchbarIcon } from "assets/img/searchbar.svg";

export const SearchButton = ({ tablet = false }) => {
  const [search, setSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const prefix = tablet ? "tablet-" : "";
  const onToggleClicked = () => {
    setShowForm(true);
    // Not sure why a timeout here makes focus work, but it does
    setTimeout(() => {
      searchInputRef?.current?.focus();
    });

    const onMouseUp = ({ target }: MouseEvent) => {
      const wrapper = document.querySelector(`.${prefix}search-form-wrap`);
      if (wrapper != target && !wrapper?.contains(target as Node)) {
        setShowForm(false);
        document.removeEventListener("mouseup", onMouseUp);
      }
    };

    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <>
      {(!showForm || !tablet) && (
        <button type="button" className={`${prefix}search-toggle`} onClick={onToggleClicked}>
          <SearchbarIcon />
        </button>
      )}
      <form
        className={`${prefix}form-search`}
        action="https://www.solo.io"
        style={{ display: showForm ? "block" : "none" }}
      >
        <input
          className="form-control"
          required
          name="s"
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          ref={searchInputRef}
        />
        {tablet ? (
          <SearchbarIcon />
        ) : (
          <button>
            <SearchbarIcon />
          </button>
        )}
      </form>
    </>
  );
};
