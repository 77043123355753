import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { ReactComponent as SearchbarIcon } from "assets/img/searchbar.svg";
// Hide top notification per request from Product
// import { TopNotification } from "./TopNotification";
import { OverviewDropdownContent } from "./dropdowns/OverviewDropdownContent";
import { ProductsDropdownContent } from "./dropdowns/ProductsDropdownContent";
import { LearnDropdownContent } from "./dropdowns/LearnDropdownContent";
import { Button, Container, Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { CompanyDropdownContent } from "./dropdowns/CompanyDropdownContent";
import { SearchButton } from "Components/Common/SearchButton";
import styled from "@emotion/styled";
import { colors } from "Styles";

const FreeTrialButton = styled(Button)`
  border: 1px solid ${colors.oceanBlue};
  font-size: 14px;
  font-weight: 600;
  left: -0.1px;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 12px !important;
  }
`;

export const MainMenu = () => {
  const [search, setSearch] = useState("");
  const [notificationOpened, setNotificationOpened] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      setScrolled(scrollPos !== 0);
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onSearch = (e: any) => setSearch(e.target.value);

  return (
    <header className={scrolled ? "scrolled" : undefined}>
      {/* {notificationOpened && <TopNotification onClose={() => setNotificationOpened(false)} />} */}
      <Navbar variant="dark" bg="primary" expand="md">
        <Container className="flex-wrap">
          <div className="logo-container d-flex w-100 align-items-center">
            <div className="logo-wrap">
              <Navbar.Brand href="/" className="mr-auto mr-md-0">
                <Logo className="logo" />
              </Navbar.Brand>
            </div>
            <div className="free-trial-mobile d-lg-none ml-auto">
              <Button variant="primary" onClick={() => window.open("https://www.solo.io/free-trial/", "_blank")}>
                FREE TRIAL
              </Button>
            </div>
            <div className="tablet-search-form-wrap">
              <SearchButton tablet={true} />
            </div>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
          </div>
          <Navbar.Collapse id="navbarSupportedContent">
            {/* Left Nvigation Start */}
            <Nav className="mr-auto">
              <Nav.Item className="d-md-none">
                <form className="mobile-form-search" action="https://www.solo.io">
                  <input
                    className="form-control"
                    required
                    name="s"
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={onSearch}
                  />
                  <SearchbarIcon />
                </form>
              </Nav.Item>

              <NavDropdown title="Overview ">
                <OverviewDropdownContent />
              </NavDropdown>

              <NavDropdown title="Products ">
                <ProductsDropdownContent />
              </NavDropdown>

              <Nav.Item>
                <Nav.Link href="https://solo.io/customers/" target="_self">
                  Customers
                </Nav.Link>
              </Nav.Item>

              <NavDropdown title="Learn ">
                <LearnDropdownContent />
              </NavDropdown>
            </Nav>
            {/* Left Nvigation End */}

            {/* Right Nvigation Start */}

            <div className="right-side-nav">
              <Nav className="mr-auto">

                <NavDropdown title="Company ">
                  <CompanyDropdownContent />
                </NavDropdown>

                <Nav.Item>
                  <Nav.Link href="https://docs.solo.io" target="_blank" className="view-doc">
                    View Docs
                  </Nav.Link>
                </Nav.Item>

                <div className="search-form-wrap search-main d-none d-lg-block">
                  {" "}
                  <SearchButton />
                </div>
                <div className="action-wrap">
                  <FreeTrialButton
                    variant="primary"
                    onClick={() => window.open("https://www.solo.io/free-trial/", "_blank")}
                    className="btn-nav btn-demo-desktop d-none d-lg-block"
                  >
                    FREE TRIAL
                  </FreeTrialButton>
                </div>
              </Nav>
            </div>
            {/* Right Navigation End */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
