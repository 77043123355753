import { Button, Nav, Row } from "react-bootstrap";

export const CompanyDropdownContent = () => {
  return (
    <Row className="nav-row">
      <div className="col-md-5 white-col nav-col">
        <div className="left-sub-nav">
          <h4>The Solo.io Story</h4>{" "}
          <p>
            Solo.io, delivers application networking software that simplifies and unifies the configuration, operation
            and visibility of the network traffic within distributed applications.
          </p>
          {/* Navigation Links Start */}
          {/* Navigation Links End */}
          {/* Navigation Buttons Start */}
          <Button variant="primary" href="https://solo.io/company/talk-to-an-expert/" target="_self">
            TALK TO AN EXPERT
          </Button>{" "}
          {/* Navigation Buttons End */}
        </div>
      </div>
      {/* /.col-md-4  */}
      <div className="col-md-4 grey-col nav-col">
        <div className="center-sub-nav">
          <ul className="nav flex-column">
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/about-us/" target="_self">
                About Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/careers/" target="_self">
                Careers
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/news-room/" target="_self">
                Newsroom
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.solo.io/partners/" target="_self">
                Partners
              </Nav.Link>
            </Nav.Item>
          </ul>
        </div>
      </div>

      {/* /.col-md-4  */}
      <div className="col-md-3 white-col nav-col">
        <div className="right-sub-nav">
          {/* Navigation start */}
          <ul className="nav flex-column">
            <Nav.Item>
              <Nav.Link href="https://soloswag.mybigcommerce.com/gear/" target="_blank" className="view-doc">
                Swag Store
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/open-source/" target="_self">
                Open Source
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/contact/" target="_self">
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://solo.io/company/get-support" target="_self">
                Get Support
              </Nav.Link>
            </Nav.Item>
          </ul>
          {/* Navigation End */}

          {/* Content and  Navigation Start */}
          {/* Content and  Navigation End */}

          {/* Text, Image and Content Start */}
        </div>
      </div>
    </Row>
  );
};
