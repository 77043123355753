import React from "react";
import { Global } from "@emotion/react";
import { globalStyles } from "./Styles";
import { Content } from "./Components/Structure/Content";
import "./Styles/styles.css";

function DocsHub() {
  return (
    <div className="App">
      <Global styles={globalStyles} />
      <Content />
    </div>
  );
}

export default DocsHub;
