import { useState } from "react";
import { Button, Container, Modal, Nav, Row } from "react-bootstrap";
export const ProductsDropdownContent = () => {

  return (
    <Container>
      <Row className="nav-row">
        <div className="col-md-5 white-col nav-col">
          <div className="left-sub-nav d-none d-md-block">
            <div className="row-img"><img src="assets/products/gloo/gloo-dark-on-light.svg" alt="Gloo" width="200px"/><a href="https://solo.io/products/"><span className="simple-link">LEARN MORE</span></a></div>{" "}
            <div className="row"><a className="product-link" href="https://solo.io/products/gloo-gateway/"><span className="product-title">Gloo Gateway</span><span><img src="assets/img/icons/kubernetes.svg" alt="Kubernetes" width="32px"/><img src="assets/img/icons/istio.svg" alt="Istio" width="32px"/><img src="assets/img/icons/envoy.svg" alt="Envoy" width="32px"/></span><span className="simple-link">LEARN MORE</span></a></div>
            <div className="row"><a className="product-link" href="https://solo.io/products/gloo-ai-gateway/"><span className="product-title">Gloo AI Gateway</span><span><img src="assets/img/icons/kubernetes.svg" alt="Kubernetes" width="32px"/><img src="assets/img/icons/istio.svg" alt="Istio" width="32px"/><img src="assets/img/icons/envoy.svg" alt="Envoy" width="32px"/></span><span className="simple-link">LEARN MORE</span></a></div>
            <div className="row"><a className="product-link" href="https://solo.io/products/gloo-mesh/"><span className="product-title">Gloo Mesh</span><span><img src="assets/img/icons/istio.svg" alt="Istio" width="32px"/><img src="assets/img/icons/envoy.svg" alt="Envoy" width="32px"/></span><span className="simple-link">LEARN MORE</span></a></div>
            {/* Navigation Links Start */}
            {/* Navigation Links End */}
            {/* Navigation Buttons Start */}
            {/* Navigation Buttons End */}
            {/* Navigation Buttons Popup Start */}
            <Button variant="primary" onClick={() => window.open("https://www.solo.io/free-trial/", "_blank")}>
              FREE TRIAL
            </Button>{" "}
            {/* Navigation Buttons Popup End */}
          </div>
        </div>
        {/* /.col-md-4  */}
        <div className="col-md-4 grey-col nav-col">
          <div className="center-sub-nav">
            <ul className="nav flex-column">
              <Nav.Item>
                <h5>Looking for Gloo Edge?</h5>
                <p>Gloo Gateway is the latest open source-based, API gateway product by Solo.io.</p>
                <p>Still want the Envoy-based Gloo Edge API gateway?</p>
                <a href="https://docs.solo.io/gloo-edge/latest/getting_started/" target="_blank" rel="noopener">Check out the docs.</a>
              </Nav.Item>
            </ul>
          </div>
        </div>

        <div className="col-md-3 white-col nav-col">
          <div className="right-sub-nav">
            {/* Navigation start */}
            {/* Navigation End */}

            {/* Content and  Navigation Start */}
            <ul className="nav flex-column">
              <Nav.Item>
                <h5>
                  <a href="https://www.solo.io/istio-support/" target="_blank" rel="noopener">
                    Get Istio Support
                  </a>
                </h5>
                <p>Need help with Istio? Solo.io provides open source Istio production support and much more.</p>
              </Nav.Item>
            </ul>
            {/* Content and  Navigation End */}

            {/* Text, Image and Content Start */}

            {/* Text, Image and Content End */}
          </div>
        </div>
      </Row>
    </Container>
  );
};
