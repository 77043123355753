import { css } from "@emotion/react";
import { colors } from "./colors";

export const globalStyles = css`
  html,
  body {
    width: 100%;
    height: 100vh;
  }

  body {
    font-family: "Proxima Nova", "Open Sans", "Helvetica", "Arial", "sans-serif";
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: white;

    font-size: 16px;
    line-height: 19px;
    font-weight: 400;

    * {
      box-sizing: border-box;
    }
  }

  /**** GLOBAL START ****/
  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position: 1rem 0;
    }

    to {
      background-position: 0 0;
    }
  }

  @keyframes progress-bar-stripes {
    0% {
      background-position: 1rem 0;
    }

    to {
      background-position: 0 0;
    }
  }

  @-webkit-keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  @-webkit-keyframes imageshadow {
    0% {
      opacity: 0.6;
    }

    50% {
      opacity: 0.8;
    }
  }

  @keyframes imageshadow {
    0% {
      opacity: 0.6;
    }

    50% {
      opacity: 0.8;
    }
  }

  :root {
    --blue: #2196c9;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #0dce93;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #2196c9;
    --secondary: #6c757d;
    --success: #0dce93;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
      "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  address,
  hr {
    margin-bottom: 1rem;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  dl,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  ul {
    margin-top: 0;
  }

  dl,
  ol,
  p,
  ul {
    margin-bottom: 1rem;
  }

  abbr[data-original-title],
  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }

  address {
    font-style: normal;
    line-height: inherit;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote,
  figure {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    color: #2196c9;
    text-decoration: none;
    background-color: transparent;
  }

  a:hover {
    color: #166587;
    text-decoration: underline;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  samp {
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }

  img,
  svg {
    vertical-align: middle;
  }

  img {
    border-style: none;
  }

  svg {
    overflow: hidden;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    border-radius: 0;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  select {
    word-wrap: normal;
  }

  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }

  .h5,
  .h6,
  h5,
  h6 {
    font-weight: 500;
  }

  .display-1,
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  .display-1 {
    font-size: 6rem;
    line-height: 1.2;
  }

  .display-2,
  .display-3,
  .display-4 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-3,
  .display-4 {
    font-size: 4.5rem;
  }

  .display-4 {
    font-size: 3.5rem;
  }

  .small,
  small {
    font-size: 80%;
    font-weight: 400;
  }

  .mark,
  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }

  .list-inline,
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }

  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }

  .blockquote-footer::before {
    content: "\\2014\\00A0";
  }

  .img-fluid,
  .img-thumbnail {
    max-width: 100%;
    height: auto;
  }

  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }

  .figure {
    display: inline-block;
  }

  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }

  code,
  kbd {
    font-size: 87.5%;
  }

  code {
    color: #e83e8c;
    word-wrap: break-word;
  }

  a > code,
  pre code {
    color: inherit;
  }

  kbd {
    padding: 0.2rem 0.4rem;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }

  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }

  pre code {
    font-size: inherit;
    word-break: normal;
  }

  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1230px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .row-img {
    padding: 5px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .product-link {
    background: #f8fafb;
    border: 1px solid #8693a5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 20px 10px;
    padding: 5px;
  }

  .product-title {
    color: #253e58;
  }

  .card > hr,
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-auto {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-first {
    order: -1;
  }

  .order-last {
    order: 13;
  }

  .order-0 {
    order: 0;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .order-3 {
    order: 3;
  }

  .order-4 {
    order: 4;
  }

  .order-5 {
    order: 5;
  }

  .order-6 {
    order: 6;
  }

  .order-7 {
    order: 7;
  }

  .order-8 {
    order: 8;
  }

  .order-9 {
    order: 9;
  }

  .order-10 {
    order: 10;
  }

  .order-11 {
    order: 11;
  }

  .order-12 {
    order: 12;
  }

  .offset-1 {
    margin-left: 8.33333333%;
  }

  .offset-2 {
    margin-left: 16.66666667%;
  }

  .offset-3 {
    margin-left: 25%;
  }

  .offset-4 {
    margin-left: 33.33333333%;
  }

  .offset-5 {
    margin-left: 41.66666667%;
  }

  .offset-6 {
    margin-left: 50%;
  }

  .offset-7 {
    margin-left: 58.33333333%;
  }

  .offset-8 {
    margin-left: 66.66666667%;
  }

  .offset-9 {
    margin-left: 75%;
  }

  .offset-10 {
    margin-left: 83.33333333%;
  }

  .offset-11 {
    margin-left: 91.66666667%;
  }

  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .row-cols-sm-3 > * {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .row-cols-sm-6 > * {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    .col-sm-1 {
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }

    .col-sm-2 {
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }

    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-sm-4 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }

    .col-sm-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }

    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-sm-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }

    .col-sm-8 {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }

    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-sm-10 {
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }

    .col-sm-11 {
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }

    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-sm-first {
      order: -1;
    }

    .order-sm-last {
      order: 13;
    }

    .order-sm-0 {
      order: 0;
    }

    .order-sm-1 {
      order: 1;
    }

    .order-sm-2 {
      order: 2;
    }

    .order-sm-3 {
      order: 3;
    }

    .order-sm-4 {
      order: 4;
    }

    .order-sm-5 {
      order: 5;
    }

    .order-sm-6 {
      order: 6;
    }

    .order-sm-7 {
      order: 7;
    }

    .order-sm-8 {
      order: 8;
    }

    .order-sm-9 {
      order: 9;
    }

    .order-sm-10 {
      order: 10;
    }

    .order-sm-11 {
      order: 11;
    }

    .order-sm-12 {
      order: 12;
    }

    .offset-sm-0 {
      margin-left: 0;
    }

    .offset-sm-1 {
      margin-left: 8.33333333%;
    }

    .offset-sm-2 {
      margin-left: 16.66666667%;
    }

    .offset-sm-3 {
      margin-left: 25%;
    }

    .offset-sm-4 {
      margin-left: 33.33333333%;
    }

    .offset-sm-5 {
      margin-left: 41.66666667%;
    }

    .offset-sm-6 {
      margin-left: 50%;
    }

    .offset-sm-7 {
      margin-left: 58.33333333%;
    }

    .offset-sm-8 {
      margin-left: 66.66666667%;
    }

    .offset-sm-9 {
      margin-left: 75%;
    }

    .offset-sm-10 {
      margin-left: 83.33333333%;
    }

    .offset-sm-11 {
      margin-left: 91.66666667%;
    }
  }

  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .row-cols-md-3 > * {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .row-cols-md-6 > * {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    .col-md-1 {
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }

    .col-md-2 {
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }

    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-md-4 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }

    .col-md-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }

    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-md-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }

    .col-md-8 {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }

    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-md-10 {
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }

    .col-md-11 {
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }

    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-md-first {
      order: -1;
    }

    .order-md-last {
      order: 13;
    }

    .order-md-0 {
      order: 0;
    }

    .order-md-1 {
      order: 1;
    }

    .order-md-2 {
      order: 2;
    }

    .order-md-3 {
      order: 3;
    }

    .order-md-4 {
      order: 4;
    }

    .order-md-5 {
      order: 5;
    }

    .order-md-6 {
      order: 6;
    }

    .order-md-7 {
      order: 7;
    }

    .order-md-8 {
      order: 8;
    }

    .order-md-9 {
      order: 9;
    }

    .order-md-10 {
      order: 10;
    }

    .order-md-11 {
      order: 11;
    }

    .order-md-12 {
      order: 12;
    }

    .offset-md-0 {
      margin-left: 0;
    }

    .offset-md-1 {
      margin-left: 8.33333333%;
    }

    .offset-md-2 {
      margin-left: 16.66666667%;
    }

    .offset-md-3 {
      margin-left: 25%;
    }

    .offset-md-4 {
      margin-left: 33.33333333%;
    }

    .offset-md-5 {
      margin-left: 41.66666667%;
    }

    .offset-md-6 {
      margin-left: 50%;
    }

    .offset-md-7 {
      margin-left: 58.33333333%;
    }

    .offset-md-8 {
      margin-left: 66.66666667%;
    }

    .offset-md-9 {
      margin-left: 75%;
    }

    .offset-md-10 {
      margin-left: 83.33333333%;
    }

    .offset-md-11 {
      margin-left: 91.66666667%;
    }
  }

  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .row-cols-lg-3 > * {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .row-cols-lg-6 > * {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    .col-lg-1 {
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }

    .col-lg-2 {
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }

    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-lg-4 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }

    .col-lg-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }

    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-lg-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }

    .col-lg-8 {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }

    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-lg-10 {
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }

    .col-lg-11 {
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }

    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-lg-first {
      order: -1;
    }

    .order-lg-last {
      order: 13;
    }

    .order-lg-0 {
      order: 0;
    }

    .order-lg-1 {
      order: 1;
    }

    .order-lg-2 {
      order: 2;
    }

    .order-lg-3 {
      order: 3;
    }

    .order-lg-4 {
      order: 4;
    }

    .order-lg-5 {
      order: 5;
    }

    .order-lg-6 {
      order: 6;
    }

    .order-lg-7 {
      order: 7;
    }

    .order-lg-8 {
      order: 8;
    }

    .order-lg-9 {
      order: 9;
    }

    .order-lg-10 {
      order: 10;
    }

    .order-lg-11 {
      order: 11;
    }

    .order-lg-12 {
      order: 12;
    }

    .offset-lg-0 {
      margin-left: 0;
    }

    .offset-lg-1 {
      margin-left: 8.33333333%;
    }

    .offset-lg-2 {
      margin-left: 16.66666667%;
    }

    .offset-lg-3 {
      margin-left: 25%;
    }

    .offset-lg-4 {
      margin-left: 33.33333333%;
    }

    .offset-lg-5 {
      margin-left: 41.66666667%;
    }

    .offset-lg-6 {
      margin-left: 50%;
    }

    .offset-lg-7 {
      margin-left: 58.33333333%;
    }

    .offset-lg-8 {
      margin-left: 66.66666667%;
    }

    .offset-lg-9 {
      margin-left: 75%;
    }

    .offset-lg-10 {
      margin-left: 83.33333333%;
    }

    .offset-lg-11 {
      margin-left: 91.66666667%;
    }
  }

  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .row-cols-xl-3 > * {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .row-cols-xl-6 > * {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    .col-xl-1 {
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }

    .col-xl-2 {
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }

    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-xl-4 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }

    .col-xl-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }

    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-xl-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }

    .col-xl-8 {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }

    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-xl-10 {
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }

    .col-xl-11 {
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }

    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-xl-first {
      order: -1;
    }

    .order-xl-last {
      order: 13;
    }

    .order-xl-0 {
      order: 0;
    }

    .order-xl-1 {
      order: 1;
    }

    .order-xl-2 {
      order: 2;
    }

    .order-xl-3 {
      order: 3;
    }

    .order-xl-4 {
      order: 4;
    }

    .order-xl-5 {
      order: 5;
    }

    .order-xl-6 {
      order: 6;
    }

    .order-xl-7 {
      order: 7;
    }

    .order-xl-8 {
      order: 8;
    }

    .order-xl-9 {
      order: 9;
    }

    .order-xl-10 {
      order: 10;
    }

    .order-xl-11 {
      order: 11;
    }

    .order-xl-12 {
      order: 12;
    }

    .offset-xl-0 {
      margin-left: 0;
    }

    .offset-xl-1 {
      margin-left: 8.33333333%;
    }

    .offset-xl-2 {
      margin-left: 16.66666667%;
    }

    .offset-xl-3 {
      margin-left: 25%;
    }

    .offset-xl-4 {
      margin-left: 33.33333333%;
    }

    .offset-xl-5 {
      margin-left: 41.66666667%;
    }

    .offset-xl-6 {
      margin-left: 50%;
    }

    .offset-xl-7 {
      margin-left: 58.33333333%;
    }

    .offset-xl-8 {
      margin-left: 66.66666667%;
    }

    .offset-xl-9 {
      margin-left: 75%;
    }

    .offset-xl-10 {
      margin-left: 83.33333333%;
    }

    .offset-xl-11 {
      margin-left: 91.66666667%;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }

  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }

  .table-sm td,
  .table-sm th {
    padding: 0.3rem;
  }

  .table-bordered,
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6;
  }

  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 2px;
  }

  .table-borderless tbody + tbody,
  .table-borderless td,
  .table-borderless th,
  .table-borderless thead th,
  .table-dark.table-bordered {
    border: 0;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table-primary,
  .table-primary > td,
  .table-primary > th {
    background-color: #c1e2f0;
  }

  .table-primary tbody + tbody,
  .table-primary td,
  .table-primary th,
  .table-primary thead th {
    border-color: #8cc8e3;
  }

  .table-hover .table-primary:hover,
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #acd8eb;
  }

  .table-secondary,
  .table-secondary > td,
  .table-secondary > th {
    background-color: #d6d8db;
  }

  .table-secondary tbody + tbody,
  .table-secondary td,
  .table-secondary th,
  .table-secondary thead th {
    border-color: #b3b7bb;
  }

  .table-hover .table-secondary:hover,
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf;
  }

  .table-success,
  .table-success > td,
  .table-success > th {
    background-color: #bbf1e1;
  }

  .table-success tbody + tbody,
  .table-success td,
  .table-success th,
  .table-success thead th {
    border-color: #81e6c7;
  }

  .table-hover .table-success:hover,
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a6edd8;
  }

  .table-info,
  .table-info > td,
  .table-info > th {
    background-color: #bee5eb;
  }

  .table-info tbody + tbody,
  .table-info td,
  .table-info th,
  .table-info thead th {
    border-color: #86cfda;
  }

  .table-hover .table-info:hover,
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }

  .table-warning,
  .table-warning > td,
  .table-warning > th {
    background-color: #ffeeba;
  }

  .table-warning tbody + tbody,
  .table-warning td,
  .table-warning th,
  .table-warning thead th {
    border-color: #ffdf7e;
  }

  .table-hover .table-warning:hover,
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }

  .table-danger,
  .table-danger > td,
  .table-danger > th {
    background-color: #f5c6cb;
  }

  .table-danger tbody + tbody,
  .table-danger td,
  .table-danger th,
  .table-danger thead th {
    border-color: #ed969e;
  }

  .table-hover .table-danger:hover,
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }

  .table-light,
  .table-light > td,
  .table-light > th {
    background-color: #fdfdfe;
  }

  .table-light tbody + tbody,
  .table-light td,
  .table-light th,
  .table-light thead th {
    border-color: #fbfcfc;
  }

  .table-hover .table-light:hover,
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }

  .table-dark > td,
  .table-dark > th {
    background-color: #c6c8ca;
  }

  .table-dark tbody + tbody {
    border-color: #95999c;
  }

  .table-hover .table-dark:hover,
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
  }

  .table-active,
  .table-active > td,
  .table-active > th,
  .table-hover .table-active:hover,
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }

  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .table-dark {
    color: #fff;
    background-color: #343a40;
  }

  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #454d55;
  }

  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }

  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }

  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }

  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #7fcaea;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }

  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }

  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }

  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }

  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }

  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }

  .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }

  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }

  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  select.form-control[multiple],
  select.form-control[size],
  textarea.form-control {
    height: auto;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-text {
    display: block;
    margin-top: 0.25rem;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }

  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    color: #6c757d;
  }

  .form-check-label {
    margin-bottom: 0;
  }

  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }

  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }

  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #0dce93;
  }

  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(13, 206, 147, 0.9);
    border-radius: 0.25rem;
  }

  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px;
  }

  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip {
    display: block;
  }

  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #0dce93;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%230dce93' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #0dce93;
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.25);
  }

  .was-validated select.form-control:valid,
  select.form-control.is-valid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center;
  }

  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }

  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    border-color: #0dce93;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 0.75rem center/8px 10px no-repeat,
      #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%230dce93' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
        center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
  }

  .custom-select.is-valid:focus,
  .was-validated .custom-select:valid:focus {
    border-color: #0dce93;
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.25);
  }

  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #0dce93;
  }

  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip,
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
  }

  .custom-control-input.is-valid ~ .custom-control-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #0dce93;
  }

  .custom-control-input.is-valid ~ .custom-control-label::before,
  .custom-file-input.is-valid ~ .custom-file-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #0dce93;
  }

  .custom-control-input.is-valid:checked ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: #1df1b0;
    background-color: #1df1b0;
  }

  .custom-control-input.is-valid:focus ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.25);
  }

  .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #0dce93;
  }

  .custom-file-input.is-valid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #0dce93;
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.25);
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
  }

  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px;
  }

  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip {
    display: block;
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated select.form-control:invalid,
  select.form-control.is-invalid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center;
  }

  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }

  .custom-select.is-invalid,
  .was-validated .custom-select:invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 0.75rem center/8px 10px no-repeat,
      #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
        center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
  }

  .custom-select.is-invalid:focus,
  .was-validated .custom-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .form-check-input.is-invalid ~ .form-check-label,
  .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #dc3545;
  }

  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip,
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
  }

  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #dc3545;
  }

  .custom-control-input.is-invalid ~ .custom-control-label::before,
  .custom-file-input.is-invalid ~ .custom-file-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #dc3545;
  }

  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }

  .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
  }

  .custom-file-input.is-invalid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline .form-check,
  input[type="button"].btn-block,
  input[type="reset"].btn-block,
  input[type="submit"].btn-block {
    width: 100%;
  }

  @media (min-width: 576px) {
    .form-inline .form-group,
    .form-inline label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .form-inline label {
      justify-content: center;
    }

    .form-inline .form-group {
      flex: 0 0 auto;
      flex-flow: row wrap;
    }

    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
      display: inline-block;
    }

    .form-inline .custom-select,
    .form-inline .input-group {
      width: auto;
    }

    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }

    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }

    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }

    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }

  .btn:hover {
    color: #212529;
    text-decoration: none;
  }

  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }

  .btn-primary {
    color: #fff;
    border-color: #2196c9;
  }

  .btn-primary.focus,
  .btn-primary:focus {
    color: #fff;
    background-color: #1c7da8;
    border-color: #1a759d;
    box-shadow: 0 0 0 0.2rem rgba(66, 166, 209, 0.5);
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #2196c9;
    border-color: #2196c9;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1a759d;
    border-color: #186d92;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 166, 209, 0.5);
  }

  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }

  .btn-secondary.focus,
  .btn-secondary:focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }

  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }

  .btn-success {
    color: #fff;
    background-color: #0dce93;
    border-color: #0dce93;
  }

  .btn-success:hover {
    color: #fff;
    background-color: #0baa79;
    border-color: #0a9e71;
  }

  .btn-success.focus,
  .btn-success:focus {
    color: #fff;
    background-color: #0baa79;
    border-color: #0a9e71;
    box-shadow: 0 0 0 0.2rem rgba(49, 213, 163, 0.5);
  }

  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #0dce93;
    border-color: #0dce93;
  }

  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0a9e71;
    border-color: #099268;
  }

  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(49, 213, 163, 0.5);
  }

  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }

  .btn-info.focus,
  .btn-info:focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }

  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }

  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }

  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }

  .btn-warning.focus,
  .btn-warning:focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }

  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-warning:not(:disabled):not(.disabled).active,
  .btn-warning:not(:disabled):not(.disabled):active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }

  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }

  .btn-danger.focus,
  .btn-danger:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }

  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }

  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }

  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  .btn-light.focus,
  .btn-light:focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }

  .btn-light.disabled,
  .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }

  .btn-light:not(:disabled):not(.disabled).active:focus,
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }

  .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }

  .btn-dark.focus,
  .btn-dark:focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }

  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }

  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }

  .btn-outline-primary {
    color: #2196c9;
    border-color: #2196c9;
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2196c9;
    border-color: #2196c9;
  }

  .btn-outline-primary.focus,
  .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.5);
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #2196c9;
    background-color: transparent;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2196c9;
    border-color: #2196c9;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.5);
  }

  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }

  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }

  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }

  .btn-outline-success {
    color: #0dce93;
    border-color: #0dce93;
  }

  .btn-outline-success:hover {
    color: #fff;
    background-color: #0dce93;
    border-color: #0dce93;
  }

  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.5);
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #0dce93;
    background-color: transparent;
  }

  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #0dce93;
    border-color: #0dce93;
  }

  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.5);
  }

  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info.focus,
  .btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }

  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning.focus,
  .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }

  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }

  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }

  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }

  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-outline-danger.focus,
  .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-outline-light.focus,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }

  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  .btn-outline-dark {
    color: #343a40;
    border-color: #343a40;
  }

  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-outline-dark.focus,
  .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
  }

  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  .btn-link {
    font-weight: 400;
    color: #2196c9;
    text-decoration: none;
  }

  .btn-link:hover {
    color: #166587;
    text-decoration: underline;
  }

  .btn-link.focus,
  .btn-link:focus {
    text-decoration: underline;
  }

  .btn-link.disabled,
  .btn-link:disabled {
    color: #6c757d;
    pointer-events: none;
  }

  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }

  .fade {
    transition: opacity 0.15s linear;
  }

  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }

  .fade:not(.show) {
    opacity: 0;
  }

  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: relative;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .dropdown-toggle:empty::after,
  .dropleft .dropdown-toggle:empty::after,
  .dropright .dropdown-toggle:empty::after,
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .dropdown-menu-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }

  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }

  .dropright .dropdown-toggle::after,
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }

  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }

  .dropright .dropdown-toggle::after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    vertical-align: 0;
  }

  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }

  .dropleft .dropdown-toggle::after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    display: none;
  }

  .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    vertical-align: 0;
  }

  .dropdown-menu[x-placement^="bottom"],
  .dropdown-menu[x-placement^="left"],
  .dropdown-menu[x-placement^="right"],
  .dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
  }

  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2196c9;
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }

  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
  }

  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }

  .btn-group-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    flex: 1 1 auto;
  }

  .btn-group-vertical > .btn:hover,
  .btn-group > .btn:hover {
    z-index: 1;
  }

  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus {
    z-index: 1;
  }

  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .btn-toolbar .input-group {
    width: auto;
  }

  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: -1px;
  }

  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
  }

  .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after {
    margin-left: 0;
  }

  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }

  .btn-group-sm > .btn + .dropdown-toggle-split,
  .btn-sm + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }

  .btn-group-lg > .btn + .dropdown-toggle-split,
  .btn-lg + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }

  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px;
  }

  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }

  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group > .custom-file + .custom-file,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .form-control,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .form-control,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
  }

  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .input-group > .custom-select:focus,
  .input-group > .form-control:focus {
    z-index: 3;
  }

  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }

  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }

  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after,
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after,
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .form-control:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append,
  .input-group-prepend {
    display: flex;
  }

  .input-group-append .btn,
  .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }

  .input-group-append .btn:focus,
  .input-group-prepend .btn:focus {
    z-index: 3;
  }

  .input-group-prepend {
    margin-right: -1px;
  }

  .input-group-append,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .input-group-text input[type="checkbox"],
  .input-group-text input[type="radio"] {
    margin-top: 0;
  }

  .input-group-lg > .custom-select,
  .input-group-lg > .form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px);
  }

  .input-group-lg > .custom-select,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-append > .btn,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  .input-group-sm > .custom-select,
  .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
  }

  .input-group-sm > .custom-select,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-append > .btn,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 1.75rem;
  }

  .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
  .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
  .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
  .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2196c9;
    background-color: #2196c9;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #7fcaea;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #abdcf1;
    border-color: #abdcf1;
  }

  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #6c757d;
  }

  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #e9ecef;
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
  }

  .custom-control-label::before {
    pointer-events: none;
    background-color: #fff;
    border: #adb5bd solid 1px;
  }

  .custom-control-label::after {
    background: 50%/50% 50% no-repeat;
  }

  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }

  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #2196c9;
    background-color: #2196c9;
  }

  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  }

  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(33, 150, 201, 0.5);
  }

  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(33, 150, 201, 0.5);
  }

  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(33, 150, 201, 0.5);
  }

  .custom-switch {
    padding-left: 2.25rem;
  }

  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
  }

  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom-select:focus {
    border-color: #7fcaea;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }

  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }

  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }

  .custom-select::-ms-expand {
    display: none;
  }

  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }

  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
  }

  .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
  }

  .custom-file,
  .custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
  }

  .custom-file {
    display: inline-block;
    margin-bottom: 0;
  }

  .custom-file-input {
    z-index: 2;
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }

  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7fcaea;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-file-input:disabled ~ .custom-file-label,
  .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #e9ecef;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }

  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }

  .custom-file-label,
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
  }

  .custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    overflow: hidden;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .custom-file-label::after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom-range:focus {
    outline: 0;
  }

  .custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .custom-range::-moz-focus-outer {
    border: 0;
  }

  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2196c9;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }

  .custom-range::-webkit-slider-thumb:active {
    background-color: #abdcf1;
  }

  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }

  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196c9;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }

  .custom-range::-moz-range-thumb:active {
    background-color: #abdcf1;
  }

  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }

  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2196c9;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none;
    }
  }

  .custom-range::-ms-thumb:active {
    background-color: #abdcf1;
  }

  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }

  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
  }

  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
  }

  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }

  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }

  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }

  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }

  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
  }

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }

  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }

  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
  }

  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #2196c9;
  }

  .nav-fill .nav-item,
  .nav-fill > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block;
  }

  .navbar {
    position: relative;
    padding: 0.5rem 1rem;
  }

  .navbar,
  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  .navbar-brand:focus,
  .navbar-brand:hover {
    text-decoration: none;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat;
  }

  .navbar-nav-scroll {
    max-height: 75vh;
    overflow-y: auto;
  }

  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }

  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }

  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }

  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }

  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-expand .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand .navbar-toggler {
    display: none;
  }

  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover,
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }

  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
  }

  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }

  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }

  .navbar-dark .navbar-brand {
    color: #fff;
  }

  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover,
  .navbar-dark .navbar-text a:focus,
  .navbar-dark .navbar-text a:hover {
    color: #fff;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }

  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
  }

  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }

  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-text a {
    color: #fff;
  }

  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }

  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }

  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }

  header .nav-item a {
    text-decoration: none;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #2196c9;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  .page-link:hover {
    z-index: 2;
    color: #166587;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.25);
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2196c9;
    border-color: #2196c9;
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }

  a.badge:focus,
  a.badge:hover {
    text-decoration: none;
  }

  .badge:empty {
    display: none;
  }

  .btn .badge {
    position: relative;
    top: -1px;
  }

  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }

  .badge-primary {
    color: #fff;
    background-color: #2196c9;
  }

  a.badge-primary:focus,
  a.badge-primary:hover {
    color: #fff;
    background-color: #1a759d;
  }

  a.badge-primary.focus,
  a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 201, 0.5);
  }

  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }

  a.badge-secondary:focus,
  a.badge-secondary:hover {
    color: #fff;
    background-color: #545b62;
  }

  a.badge-secondary.focus,
  a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }

  .badge-success {
    color: #fff;
    background-color: #0dce93;
  }

  a.badge-success:focus,
  a.badge-success:hover {
    color: #fff;
    background-color: #0a9e71;
  }

  a.badge-success.focus,
  a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 206, 147, 0.5);
  }

  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }

  a.badge-info:focus,
  a.badge-info:hover {
    color: #fff;
    background-color: #117a8b;
  }

  a.badge-info.focus,
  a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }

  a.badge-warning:focus,
  a.badge-warning:hover {
    color: #212529;
    background-color: #d39e00;
  }

  a.badge-warning.focus,
  a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }

  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }

  a.badge-danger:focus,
  a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130;
  }

  a.badge-danger.focus,
  a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }

  a.badge-light:focus,
  a.badge-light:hover {
    color: #212529;
    background-color: #dae0e5;
  }

  a.badge-light.focus,
  a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  .badge-dark {
    color: #fff;
    background-color: #343a40;
  }

  a.badge-dark:focus,
  a.badge-dark:hover {
    color: #fff;
    background-color: #1d2124;
  }

  a.badge-dark.focus,
  a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }

  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }

  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert-heading {
    color: inherit;
  }

  .alert-link {
    font-weight: 700;
  }

  .alert-dismissible {
    padding-right: 4rem;
  }

  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }

  .alert-primary {
    color: #114e69;
    background-color: #d3eaf4;
    border-color: #c1e2f0;
  }

  .alert-primary hr {
    border-top-color: #acd8eb;
  }

  .alert-primary .alert-link {
    color: #0a2d3d;
  }

  .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }

  .alert-secondary hr {
    border-top-color: #c8cbcf;
  }

  .alert-secondary .alert-link {
    color: #202326;
  }

  .alert-success {
    color: #076b4c;
    background-color: #cff5e9;
    border-color: #bbf1e1;
  }

  .alert-success hr {
    border-top-color: #a6edd8;
  }

  .alert-success .alert-link {
    color: #043b2a;
  }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  .alert-info hr {
    border-top-color: #abdde5;
  }

  .alert-info .alert-link {
    color: #062c33;
  }

  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .alert-warning hr {
    border-top-color: #ffe8a1;
  }

  .alert-warning .alert-link {
    color: #533f03;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .alert-danger hr {
    border-top-color: #f1b0b7;
  }

  .alert-danger .alert-link {
    color: #491217;
  }

  .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }

  .alert-light hr {
    border-top-color: #ececf6;
  }

  .alert-light .alert-link {
    color: #686868;
  }

  .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
  }

  .alert-dark hr {
    border-top-color: #b9bbbe;
  }

  .alert-dark .alert-link {
    color: #040505;
  }

  .progress,
  .progress-bar {
    display: flex;
    overflow: hidden;
  }

  .progress {
    height: 1rem;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }

  .progress-bar {
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2196c9;
    transition: width 0.6s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }

  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
  }

  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }

  .media {
    display: flex;
    align-items: flex-start;
  }

  .media-body {
    flex: 1;
  }

  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
  }

  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }

  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }

  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
  }

  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2196c9;
    border-color: #2196c9;
  }

  .list-group-item + .list-group-item {
    border-top-width: 0;
  }

  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }

  .list-group-horizontal {
    flex-direction: row;
  }

  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form .hs-error-msgs li,
  #hsForm_8aa4ea91-216a-467a-b8bf-fdc43b2cf24f .hs-error-msgs li,
  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form .hs-error-msgs li,
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }

  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }

    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }

    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }

  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }

    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }

  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }

    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }

  .list-group-flush {
    border-radius: 0;
  }

  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }

  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }

  .list-group-item-primary {
    color: #114e69;
    background-color: #c1e2f0;
  }

  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #114e69;
    background-color: #acd8eb;
  }

  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #114e69;
    border-color: #114e69;
  }

  .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
  }

  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #383d41;
    background-color: #c8cbcf;
  }

  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
  }

  .list-group-item-success {
    color: #076b4c;
    background-color: #bbf1e1;
  }

  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #076b4c;
    background-color: #a6edd8;
  }

  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #076b4c;
    border-color: #076b4c;
  }

  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }

  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #0c5460;
    background-color: #abdde5;
  }

  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
  }

  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }

  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #856404;
    background-color: #ffe8a1;
  }

  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
  }

  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }

  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #721c24;
    background-color: #f1b0b7;
  }

  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
  }

  .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }

  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6;
  }

  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
  }

  .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
  }

  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #1b1e21;
    background-color: #b9bbbe;
  }

  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }

  .close:hover {
    color: #000;
    text-decoration: none;
  }

  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  a.close.disabled {
    pointer-events: none;
  }

  .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
  }

  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .toast.showing {
    opacity: 1;
  }

  .toast.show {
    display: block;
    opacity: 1;
  }

  .toast.hide {
    display: none;
  }

  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }

  .toast-body {
    padding: 0.75rem;
  }

  .modal,
  .modal-open {
    overflow: hidden;
    height: auto;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    outline: 0;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }

  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }

  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }

  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }

  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }

  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }

  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }

  .modal-backdrop.fade {
    opacity: 0;
  }

  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .modal-footer > * {
    margin: 0.25rem;
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
    }

    .modal-sm {
      max-width: 300px;
    }
  }

  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }

  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }

  .tooltip {
    z-index: 1070;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
      "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }

  .tooltip.show {
    opacity: 0.9;
  }

  .tooltip,
  .tooltip .arrow {
    position: absolute;
    display: block;
  }

  .tooltip .arrow {
    width: 0.8rem;
    height: 0.4rem;
  }

  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .bs-tooltip-auto[x-placement^="top"],
  .bs-tooltip-top {
    padding: 0.4rem 0;
  }

  .bs-tooltip-auto[x-placement^="top"] .arrow,
  .bs-tooltip-top .arrow {
    bottom: 0;
  }

  .bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }

  .bs-tooltip-auto[x-placement^="right"],
  .bs-tooltip-right {
    padding: 0 0.4rem;
  }

  .bs-tooltip-auto[x-placement^="right"] .arrow,
  .bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }

  .bs-tooltip-auto[x-placement^="right"] .arrow::before,
  .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }

  .bs-tooltip-auto[x-placement^="bottom"],
  .bs-tooltip-bottom {
    padding: 0.4rem 0;
  }

  .bs-tooltip-auto[x-placement^="bottom"] .arrow,
  .bs-tooltip-bottom .arrow {
    top: 0;
  }

  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }

  .bs-tooltip-auto[x-placement^="left"],
  .bs-tooltip-left {
    padding: 0 0.4rem;
  }

  .bs-tooltip-auto[x-placement^="left"] .arrow,
  .bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }

  .bs-tooltip-auto[x-placement^="left"] .arrow::before,
  .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }

  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }

  .popover,
  .popover .arrow {
    position: absolute;
    display: block;
  }

  .popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
      "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }

  .popover .arrow {
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }

  .popover .arrow::after,
  .popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .bs-popover-auto[x-placement^="top"],
  .bs-popover-top {
    margin-bottom: 0.5rem;
  }

  .bs-popover-auto[x-placement^="top"] > .arrow,
  .bs-popover-top > .arrow {
    bottom: calc(-0.5rem - 1px);
  }

  .bs-popover-auto[x-placement^="top"] > .arrow::before,
  .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }

  .bs-popover-auto[x-placement^="top"] > .arrow::after,
  .bs-popover-top > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }

  .bs-popover-auto[x-placement^="right"],
  .bs-popover-right {
    margin-left: 0.5rem;
  }

  .bs-popover-auto[x-placement^="right"] > .arrow,
  .bs-popover-right > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

  .bs-popover-auto[x-placement^="right"] > .arrow::before,
  .bs-popover-right > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }

  .bs-popover-auto[x-placement^="right"] > .arrow::after,
  .bs-popover-right > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }

  .bs-popover-auto[x-placement^="bottom"],
  .bs-popover-bottom {
    margin-top: 0.5rem;
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow,
  .bs-popover-bottom > .arrow {
    top: calc(-0.5rem - 1px);
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }

  .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }

  .bs-popover-auto[x-placement^="left"],
  .bs-popover-left {
    margin-right: 0.5rem;
  }

  .bs-popover-auto[x-placement^="left"] > .arrow,
  .bs-popover-left > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

  .bs-popover-auto[x-placement^="left"] > .arrow::before,
  .bs-popover-left > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
  }

  .bs-popover-auto[x-placement^="left"] > .arrow::after,
  .bs-popover-left > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }

  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .popover-header:empty {
    display: none;
  }

  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
  }

  header .navbar-expand-lg > .container {
    position: relative;
  }

  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }

  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
  }

  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }

  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
  }

  .bg-primary {
    background-color: #2196c9 !important;
  }

  a.bg-primary:focus,
  a.bg-primary:hover,
  button.bg-primary:focus,
  button.bg-primary:hover {
    background-color: #1a759d !important;
  }

  .bg-secondary {
    background-color: #6c757d !important;
  }

  a.bg-secondary:focus,
  a.bg-secondary:hover,
  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background-color: #545b62 !important;
  }

  .bg-success {
    background-color: #0dce93 !important;
  }

  a.bg-success:focus,
  a.bg-success:hover,
  button.bg-success:focus,
  button.bg-success:hover {
    background-color: #0a9e71 !important;
  }

  .bg-info {
    background-color: #17a2b8 !important;
  }

  a.bg-info:focus,
  a.bg-info:hover,
  button.bg-info:focus,
  button.bg-info:hover {
    background-color: #117a8b !important;
  }

  .bg-warning {
    background-color: #ffc107 !important;
  }

  a.bg-warning:focus,
  a.bg-warning:hover,
  button.bg-warning:focus,
  button.bg-warning:hover {
    background-color: #d39e00 !important;
  }

  .bg-danger {
    background-color: #dc3545 !important;
  }

  a.bg-danger:focus,
  a.bg-danger:hover,
  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #bd2130 !important;
  }

  .bg-light {
    background-color: #f8f9fa !important;
  }

  a.bg-light:focus,
  a.bg-light:hover,
  button.bg-light:focus,
  button.bg-light:hover {
    background-color: #dae0e5 !important;
  }

  .bg-dark {
    background-color: #343a40 !important;
  }

  a.bg-dark:focus,
  a.bg-dark:hover,
  button.bg-dark:focus,
  button.bg-dark:hover {
    background-color: #1d2124 !important;
  }

  .bg-white {
    background-color: #fff !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .border {
    border: 1px solid #dee2e6 !important;
  }

  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top-0 {
    border-top: 0 !important;
  }

  .border-right-0 {
    border-right: 0 !important;
  }

  .border-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-left-0 {
    border-left: 0 !important;
  }

  .border-primary {
    border-color: #2196c9 !important;
  }

  .border-secondary {
    border-color: #6c757d !important;
  }

  .border-success {
    border-color: #0dce93 !important;
  }

  .border-info {
    border-color: #17a2b8 !important;
  }

  .border-warning {
    border-color: #ffc107 !important;
  }

  .border-danger {
    border-color: #dc3545 !important;
  }

  .border-light {
    border-color: #f8f9fa !important;
  }

  .border-dark {
    border-color: #343a40 !important;
  }

  .border-white {
    border-color: #fff !important;
  }

  .rounded-sm {
    border-radius: 0.2rem !important;
  }

  .rounded {
    border-radius: 0.25rem !important;
  }

  .rounded-right,
  .rounded-top {
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top {
    border-top-left-radius: 0.25rem !important;
  }

  .rounded-right {
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom,
  .rounded-left {
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-left {
    border-top-left-radius: 0.25rem !important;
  }

  .rounded-lg {
    border-radius: 0.3rem !important;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .rounded-pill {
    border-radius: 50rem !important;
  }

  .rounded-0 {
    border-radius: 0 !important;
  }

  .d-none {
    display: none !important;
  }

  .d-inline {
    display: inline !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .d-block {
    display: block !important;
  }

  .d-table {
    display: table !important;
  }

  .d-table-row {
    display: table-row !important;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  .d-flex {
    display: flex !important;
  }

  .d-inline-flex {
    display: inline-flex !important;
  }

  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }

    .d-sm-inline {
      display: inline !important;
    }

    .d-sm-inline-block {
      display: inline-block !important;
    }

    .d-sm-block {
      display: block !important;
    }

    .d-sm-table {
      display: table !important;
    }

    .d-sm-table-row {
      display: table-row !important;
    }

    .d-sm-table-cell {
      display: table-cell !important;
    }

    .d-sm-flex {
      display: flex !important;
    }

    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }

  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }

    .d-md-inline {
      display: inline !important;
    }

    .d-md-inline-block {
      display: inline-block !important;
    }

    .d-md-block {
      display: block !important;
    }

    .d-md-table {
      display: table !important;
    }

    .d-md-table-row {
      display: table-row !important;
    }

    .d-md-table-cell {
      display: table-cell !important;
    }

    .d-md-flex {
      display: flex !important;
    }

    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }

  @media (min-width: 992px) {

    .d-lg-inline {
      display: inline !important;
    }

    .d-lg-inline-block {
      display: inline-block !important;
    }

    .d-lg-block {
      display: block !important;
    }

    .d-lg-table {
      display: table !important;
    }

    .d-lg-table-row {
      display: table-row !important;
    }

    .d-lg-table-cell {
      display: table-cell !important;
    }

    .d-lg-flex {
      display: flex !important;
    }

    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }

  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }

    .d-xl-inline {
      display: inline !important;
    }

    .d-xl-inline-block {
      display: inline-block !important;
    }

    .d-xl-block {
      display: block !important;
    }

    .d-xl-table {
      display: table !important;
    }

    .d-xl-table-row {
      display: table-row !important;
    }

    .d-xl-table-cell {
      display: table-cell !important;
    }

    .d-xl-flex {
      display: flex !important;
    }

    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }

  @media print {
    .d-print-none {
      display: none !important;
    }

    .d-print-inline {
      display: inline !important;
    }

    .d-print-inline-block {
      display: inline-block !important;
    }

    .d-print-block {
      display: block !important;
    }

    .d-print-table {
      display: table !important;
    }

    .d-print-table-row {
      display: table-row !important;
    }

    .d-print-table-cell {
      display: table-cell !important;
    }

    .d-print-flex {
      display: flex !important;
    }

    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive::before {
    display: block;
    content: "";
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .embed-responsive-21by9::before {
    padding-top: 42.85714286%;
  }

  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }

  .embed-responsive-4by3::before {
    padding-top: 75%;
  }

  .embed-responsive-1by1::before {
    padding-top: 100%;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-fill {
    flex: 1 1 auto !important;
  }

  .flex-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }

  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }

    .flex-sm-column {
      flex-direction: column !important;
    }

    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
      flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
  }

  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }

    .flex-md-column {
      flex-direction: column !important;
    }

    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
      flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
      flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
  }

  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important;
    }

    .flex-lg-column {
      flex-direction: column !important;
    }

    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
      flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
  }

  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important;
    }

    .flex-xl-column {
      flex-direction: column !important;
    }

    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
      flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
  }

  .float-left {
    float: left !important;
  }

  .float-right {
    float: right !important;
  }

  .float-none {
    float: none !important;
  }

  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }

    .float-sm-right {
      float: right !important;
    }

    .float-sm-none {
      float: none !important;
    }
  }

  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }

    .float-md-right {
      float: right !important;
    }

    .float-md-none {
      float: none !important;
    }
  }

  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }

    .float-lg-right {
      float: right !important;
    }

    .float-lg-none {
      float: none !important;
    }
  }

  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }

    .float-xl-right {
      float: right !important;
    }

    .float-xl-none {
      float: none !important;
    }
  }

  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }

  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important;
  }

  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
  }

  .overflow-auto {
    overflow: auto !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .position-static {
    position: static !important;
  }

  .position-relative {
    position: relative !important;
  }

  .position-absolute {
    position: absolute !important;
  }

  .position-fixed {
    position: fixed !important;
  }

  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .fixed-bottom,
  .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .fixed-top {
    top: 0;
  }

  .fixed-bottom {
    bottom: 0;
  }

  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

  .shadow-none {
    box-shadow: none !important;
  }

  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }

  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent;
  }

  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .text-justify {
    text-align: justify !important;
  }

  .text-wrap {
    white-space: normal !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }

    .text-sm-right {
      text-align: right !important;
    }

    .text-sm-center {
      text-align: center !important;
    }
  }

  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }

    .text-md-right {
      text-align: right !important;
    }

    .text-md-center {
      text-align: center !important;
    }
  }

  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }

    .text-lg-right {
      text-align: right !important;
    }

    .text-lg-center {
      text-align: center !important;
    }
  }

  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }

    .text-xl-right {
      text-align: right !important;
    }

    .text-xl-center {
      text-align: center !important;
    }
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-capitalize {
    text-transform: capitalize !important;
  }

  .font-weight-light {
    font-weight: 300 !important;
  }

  .font-weight-lighter {
    font-weight: lighter !important;
  }

  .font-weight-normal {
    font-weight: 400 !important;
  }

  .font-weight-bold {
    font-weight: 700 !important;
  }

  .font-weight-bolder {
    font-weight: bolder !important;
  }

  .font-italic {
    font-style: italic !important;
  }

  .text-white {
    color: #fff !important;
  }

  .text-primary {
    color: #2196c9 !important;
  }

  a.text-primary:focus,
  a.text-primary:hover {
    color: #166587 !important;
  }

  .text-secondary {
    color: #6c757d !important;
  }

  a.text-secondary:focus,
  a.text-secondary:hover {
    color: #494f54 !important;
  }

  .text-success {
    color: #0dce93 !important;
  }

  a.text-success:focus,
  a.text-success:hover {
    color: #088660 !important;
  }

  .text-info {
    color: #17a2b8 !important;
  }

  a.text-info:focus,
  a.text-info:hover {
    color: #0f6674 !important;
  }

  .text-warning {
    color: #ffc107 !important;
  }

  a.text-warning:focus,
  a.text-warning:hover {
    color: #ba8b00 !important;
  }

  .text-danger {
    color: #dc3545 !important;
  }

  a.text-danger:focus,
  a.text-danger:hover {
    color: #a71d2a !important;
  }

  .text-light {
    color: #f8f9fa !important;
  }

  a.text-light:focus,
  a.text-light:hover {
    color: #cbd3da !important;
  }

  .text-dark {
    color: #343a40 !important;
  }

  a.text-dark:focus,
  a.text-dark:hover {
    color: #121416 !important;
  }

  .text-body {
    color: #212529 !important;
  }

  .text-muted {
    color: #6c757d !important;
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
  }

  .text-reset {
    color: inherit !important;
  }

  .visible {
    visibility: visible !important;
  }

  .invisible {
    visibility: hidden !important;
  }

  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none !important;
      box-shadow: none !important;
    }

    a:not(.btn) {
      text-decoration: underline;
    }

    abbr[title]::after {
      content: " (" attr(title) ")";
    }

    pre {
      white-space: pre-wrap !important;
    }

    blockquote,
    pre {
      border: 1px solid #adb5bd;
    }

    blockquote,
    img,
    pre,
    tr {
      page-break-inside: avoid;
    }

    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    .container,
    body {
      min-width: 992px !important;
    }

    .navbar {
      display: none;
    }

    .badge {
      border: 1px solid #000;
    }

    .table {
      border-collapse: collapse !important;
    }

    .table td,
    .table th {
      background-color: #fff !important;
    }

    .table-bordered td,
    .table-bordered th {
      border: 1px solid #dee2e6 !important;
    }

    .table-dark {
      color: inherit;
    }

    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
      border-color: #dee2e6;
    }

    .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6;
    }
  }

  body,
  html {
    font-family: "proxima-nova", sans-serif;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  h1,
  h2,
  h3,
  h4 {
    font-weight: 400;
  }

  .h1,
  h1 {
    font-size: 2.625rem;
  }

  .h2,
  h2 {
    font-size: 2.25rem;
  }

  .h3,
  h3 {
    font-size: 1.75rem;
  }

  .h4,
  h4 {
    font-size: 1.5rem;
  }

  .h5,
  h5 {
    font-size: 1.25rem;
  }

  .h6,
  h6,
  p {
    font-size: 1.125rem;
  }

  .p-lead {
    font-size: 1.25rem;
  }

  .p-sm {
    font-size: 1rem;
  }

  .dark-bg,
  .dark-bg .h1,
  .dark-bg .h2,
  .dark-bg .h3,
  .dark-bg .h4,
  .dark-bg .h5,
  .dark-bg .h6,
  .dark-bg h1,
  .dark-bg h2,
  .dark-bg h3,
  .dark-bg h4,
  .dark-bg h5,
  .dark-bg h6,
  .dark-bg p {
    color: #fff;
  }

  @media (max-width: 991px) {
    .h1,
    h1 {
      font-size: 2.375rem;
    }

    .h2,
    h2 {
      font-size: 2.125rem;
    }

    .h3,
    h3 {
      font-size: 1.5rem;
      line-height: 1.2;
    }

    .h4,
    h4 {
      font-size: 1.125rem;
    }

    .h5,
    h5,
    p {
      font-size: 1rem;
    }

    .h6,
    h6 {
      font-size: 0.875rem;
    }

    .p-lead {
      font-size: 1.125rem;
    }

    .p-sm {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 575px) {
    .h1,
    .h2,
    h1,
    h2 {
      font-size: 1.5rem;
    }

    .h3,
    h3 {
      font-size: 1.25rem;
    }

    .p-lead {
      font-size: 1rem;
    }
  }

  .visuallyhidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0 0 99.9% 99.9%);
    clip-path: inset(0 0 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
  }

  body main,
  html {
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }

  pre {
    display: block;
    font-size: 87.5%;
    padding: 45px 20px 20px;
    color: #445870 !important;
  }

  .single pre {
    background-color: #f6f7f9 !important;
  }

  section {
    padding: 4rem 0;
  }

  @media (max-width: 1199px) {
    section {
      padding: 4rem 0;
    }
  }

  @media (max-width: 991px) {
    section {
      padding: 3rem 0;
    }
  }

  @media (max-width: 767px) {
    section {
      padding: 2rem 0;
    }
  }

  .btn,
  .btn:hover {
    border-radius: 2px;
  }

  a,
  button {
    transition: 300ms ease all;
  }

  .btn-primary {
    background-color: ${colors.oceanBlue};
  }

  .btn-primary:hover {
    background-color: #55b8e3;
    color: #fff;
    border-color: transparent;
  }

  .btn-blue,
  .btn-primary,
  .btn-white,
  .btn-white-border {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 22px;
    left: -0.1px;
  }

  .btn-white {
    background-color: #fff;
    border: 1px solid ${colors.oceanBlue};
    color: ${colors.oceanBlue};
  }

  body main {
    transition: all 0.3s;
  }

  @media only screen and (max-width: 767px) {
    body.logged-in.admin-bar {
      margin-top: 32px;
    }

    body.logged-in.admin-bar main {
      margin-top: 0 !important;
    }
  }

  body.logged-in.admin-bar main {
    margin-top: 32px;
  }

  .btn-blue,
  .btn-white-border {
    color: #fff;
  }

  .btn-blue {
    background-color: ${colors.oceanBlue};
    border: 1px solid ${colors.oceanBlue};
  }

  .btn-blue:hover,
  .btn-white-border:hover,
  .btn-white:hover {
    background-color: #55b8e3;
    color: #fff;
    border: 1px solid #55b8e3;
  }

  .btn-white-border {
    border: 1px solid #fff;
  }

  img {
    max-width: 100%;
  }

  h2,
  p {
    letter-spacing: 0;
  }

  h2,
  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .grey-col ul.nav .nav-item a.nav-link {
    color: #253e58;
  }

  p {
    color: #35393b;
  }

  .listing-section h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .listing-section .row {
    justify-content: center;
  }

  header .nav-item.active a.nav-link.singl-nav,
  header .nav-item.dropdown.active .nav-item.active,
  header .nav-item.dropdown.active .nav-link.dropdown-toggle,
  header .navbar .dropdown.active .nav-item.active a.nav-link,
  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .grey-col ul.nav .nav-item a.nav-link:hover,
  header .navbar .navbar-collapse .navbar-nav .nav-item.show .nav-link,
  header .navbar .navbar-collapse .navbar-nav .nav-link.active,
  header .navbar .navbar-collapse .navbar-nav .nav-link:hover,
  header .navbar-dark .navbar-nav .show > .nav-link {
    color: ${colors.oceanBlue};
  }

  div#hs-eu-cookie-confirmation {
    top: initial !important;
    bottom: 0 !important;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    .container {
      max-width: 1020px;
    }
  }

  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  header.scrolled {
    box-shadow: 0 7px 10px #253e580b;
  }

  header .nav-item .nav-link:hover {
    color: ${colors.oceanBlue} !important;
  }

  header .bg-primary {
    background-color: #f8fafb !important;
  }

  header .navbar-dark .navbar-toggler {
    color: #000;
    border-color: #000;
  }
  header .navbar-dark .navbar-toggler-icon {
    filter: invert(1);
  }

  header .mobile-form-search,
  header .tablet-form-search {
    position: relative;
  }

  header .mobile-form-search img,
  header .tablet-form-search img,
  header .mobile-form-search svg,
  header .tablet-form-search svg {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
  }

  header .tablet-form-search input {
    width: 290px;
  }

  header .free-trial-mobile {
    margin-right: 18px;
    position: relative;
  }

  header .search-form-wrap {
    width: 35px;
    height: 35px;
  }

  header .search-form-wrap .search-toggle,
  header .search-form-wrap form button {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
  }

  header .search-form-wrap form {
    display: none;
    position: absolute;
  }

  header .search-form-wrap form input[type="text"] {
    background-color: #2196c9;
    color: #fff;
    border: 0;
    border-radius: 10px;
    width: 100%;
    height: 35px;
    padding: 0 1rem;
  }

  header .search-form-wrap form button {
    position: absolute;
    right: 0;
    top: 0;
  }

  header .search-form-wrap form button svg *[fill] {
    fill: #fff;
  }

  header .search-form-wrap form ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  header .search-form-wrap form ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  header .search-form-wrap.search-main {
    margin: 0 12px;
  }

  header .search-form-wrap.search-main .search-toggle {
    margin-top: 4px;
  }

  header .search-form-wrap.search-main form {
    width: 100%;
    max-width: 1199px;
    right: 0;
    left: 0;
    top: 85px;
    margin: auto;
  }

  header .search-form-wrap.search-tablet {
    margin-left: 1rem;
  }

  header .search-form-wrap.search-tablet .search-toggle {
    position: absolute;
    right: 0;
    top: 12px;
  }

  header .search-form-wrap.search-tablet form {
    width: calc(67vw - 30px);
    right: 0;
    top: 12px;
  }

  header .top-notification-bar {
    background-color: #44ba8c;
    padding: 10px 0;
  }

  header .top-notification-bar p {
    color: #fff;
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: 329px) {
    header .top-notification-bar p {
      width: 85%;
    }
  }

  header .top-notification-bar p a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin-left: 7px;
  }

  header .top-notification-bar p a:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    border-right: 0.1em solid #fff;
    border-top: 0.1em solid #fff;
    transform: rotate(45deg) translate(2px, -3px);
    margin-right: 0.5em;
  }

  header .top-notification-bar .container {
    display: flex;
    justify-content: center;
    position: relative;
  }

  header .top-notification-bar .close-btn {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
  }

  header .right-side-nav .action-wrap {
    margin-top: 3px;
  }

  header .right-side-nav .action-wrap a.btn.btn-white {
    border: 1px solid ${colors.oceanBlue};
    font-size: 14px;
    font-weight: 600;
    left: -0.1px;
  }

  header .navbar {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 300ms ease;
    background-color: #253e58;
  }

  header .navbar .tablet-search-form-wrap form {
    display: none;
  }

  header .navbar .tablet-search-form-wrap .tablet-search-toggle {
    display: none;
    background-color: transparent;
    border: 0;
  }

  header .navbar .tablet-search-form-wrap .tablet-search-toggle img,
  header .navbar .tablet-search-form-wrap .tablet-search-toggle svg {
    width: 27px;
    height: 27px;
  }

  header .navbar.scrolled {
    background-color: #253e58;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.04);
  }

  header .navbar .navbar-collapse {
    border-top: 1px solid #dbe4ec;
    margin-top: 19px;
    padding-top: 21px;
  }

  header .navbar .navbar-collapse .navbar-nav {
    padding-right: 0;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-link {
    padding: 12px;
    font-size: 15px;
    color: #253e58;
    font-weight: 600;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-link.dropdown-toggle:after {
    width: 8px;
    height: 8px;
    border: 0;
    border-left: 1px solid #253e58;
    border-bottom: 1px solid #253e58;
    transform: rotate(-45deg) translate(-3px, -2px);
    margin-left: 0.5rem;
    transition: all 0.3s ease;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-link.view-doc {
    white-space: nowrap;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-link.view-doc:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath data-name='Rectangle 3917' fill='none' d='M0 0h18v18H0z'/%3E%3Cg data-name='Group 24414' fill='%23253e58'%3E%3Cpath data-name='Path 4245' d='M14.875 2.636a.529.529 0 00-.064.01h-4.179a.535.535 0 000 1.069h2.97l-6.535 6.572a.537.537 0 000 .756.53.53 0 00.752 0l6.534-6.577v2.987a.532.532 0 101.063 0V3.246a.537.537 0 00-.131-.43.53.53 0 00-.411-.177z'/%3E%3Cpath data-name='Path 4246' d='M7.442 4.78a30.45 30.45 0 00-3.129.089 3.022 3.022 0 00-1.9.742 3.055 3.055 0 00-.735 1.906 31.079 31.079 0 00-.084 3.144 30.486 30.486 0 00.09 3.145 3.061 3.061 0 00.742 1.906 3.028 3.028 0 001.895.741 30.317 30.317 0 003.12.089 30 30 0 003.12-.091 3.033 3.033 0 001.9-.743 3.064 3.064 0 00.74-1.906 30.368 30.368 0 00.091-3.141.532.532 0 10-1.063 0c0 1.33 0 2.322-.084 3.02a2.078 2.078 0 01-.436 1.271 2.054 2.054 0 01-1.264.438c-.694.08-1.68.084-3 .084s-2.306 0-3-.082a2.055 2.055 0 01-1.264-.435 2.077 2.077 0 01-.44-1.277c-.08-.7-.084-1.691-.084-3.023s0-2.325.081-3.024a2.075 2.075 0 01.432-1.27 2.049 2.049 0 011.263-.435c.7-.079 1.683-.082 3.009-.082a.535.535 0 000-1.066z'/%3E%3C/g%3E%3C/svg%3E");
    height: 18px;
    width: 18px;
    display: inline-block;
    position: relative;
    left: 4px;
    top: 2px;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-item.show .nav-link.dropdown-toggle:after {
    transform: rotate(-227deg) translate(6px, -2px);
    border-left: 1px solid ${colors.oceanBlue};
    border-bottom: 1px solid ${colors.oceanBlue};
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu {
    width: 100%;
    padding: 0;
    border: 1px solid #f5f8fa;
    box-shadow: 0 8px 16px #253e5827;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: #f5f8fa;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .white-col {
    background-color: #fff;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .nav-row {
    margin-left: -15px;
    margin-right: -40px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .nav-col {
    padding-left: 30px;
    padding-right: 30px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .grey-col {
    background-color: #f5f8fa;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .left-sub-nav {
    padding: 30px 0 50px 5px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .left-sub-nav .simple-link {
    font-size: 14px;
    text-decoration: none;
    margin-right: 30px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .left-sub-nav .simple-link:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-right: 0.1em solid ${colors.oceanBlue};
    border-top: 0.1em solid ${colors.oceanBlue};
    transition: 300ms ease all;
    transform: rotate(45deg) translate(2px, -4px);
    margin-right: 0.5em;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .left-sub-nav .simple-link:hover {
    opacity: 0.7;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .left-sub-nav .simple-link:hover:after {
    margin-left: 10px;
    margin-right: -10px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .btn {
    font-size: 14px;
    margin-right: 15px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu ul {
    margin-left: 10px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu ul.nav.flex-column ul {
    margin-left: 15px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu ul.nav.flex-column ul .nav-item .nav-link {
    font-size: 17px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu ul p {
    font-size: 14px;
    padding-right: 13px;
    margin-bottom: 5px;
    line-height: 22px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .center-sub-nav {
    padding: 30px 0 60px;
    height: 100%;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .right-sub-nav {
    padding: 30px 0;
  }

  header
    .navbar
    .navbar-collapse
    .navbar-nav
    .dropdown-menu
    .right-sub-nav
    .nav.flex-column
    .nav.flex-column
    .nav-link:hover {
    color: #166587;
    opacity: 0.7;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu h4 {
    font-size: 28px;
    color: #253e58;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu p {
    font-size: 18px;
    font-family: "proxima-nova";
    line-height: 26px;
    color: #545d6a;
    font-weight: 200;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .nav-item .nav-link {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 0;
    padding-left: 0;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .nav-item h5 {
    padding-top: 0.7rem;
    color: #253e58;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .event-item span {
    color: #8693a5;
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
  }

  header .navbar .navbar-collapse .navbar-nav .dropdown-menu .event-item .image-wrap {
    margin-bottom: 20px;
  }

  @media (min-width: 992px) {
    header .w-100 {
      width: auto !important;
    }
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    header .navbar .navbar-nav .nav-link {
      font-size: 13px;
    }

    header .right-side-nav .action-wrap a.btn.btn-white {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 992px) {
    header .navbar {
      padding-left: 0;
      padding-right: 0;
    }

    header .navbar .logo {
      height: 60px;
      width: 160px;
      margin-right: 1rem;
    }

    header .navbar .dropdown {
      position: unset;
    }

    header .navbar .dropdown-menu.right {
      right: 0;
    }

    header .navbar .dropdown-menu .col-md-4 {
      flex: 0 0 31.333333%;
      max-width: 31.333333%;
    }
  }

  @media screen and (max-width: 1199px) {
    header .navbar .logo {
      height: 51px;
      width: auto;
    }

    header .btn,
    header .nav-link {
      font-size: 0.875rem;
    }

    header .search-form-wrap.search-main form {
      top: 125px;
      right: 0;
    }
  }

  @media screen and (max-width: 991px) {
    header .search-form-wrap.search-main form {
      width: calc(100% - 200px);
    }

    header .navbar .navbar-nav .dropdown-menu h4,
    header .navbar .navbar-nav .dropdown-menu h5 {
      font-size: 1.125rem;
    }

    header .navbar .navbar-nav .dropdown-menu .nav-item .nav-link,
    header .navbar .navbar-nav .dropdown-menu .ul.nav.flex-column ul .nav-item .nav-link,
    header .navbar .navbar-nav .dropdown-menu p {
      font-size: 1rem;
    }

    header .navbar .navbar-nav .dropdown-menu .right-sub-nav {
      padding: 30px 25px;
    }
  }

  @media screen and (max-width: 768px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
      display: flex;
      font-size: 18px;
    }

    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.dropdown-toggle:after {
      margin-left: auto;
      transform: scale(1.5) rotate(-45deg) translate(-3px, -2px);
    }

    header .navbar .navbar-collapse .navbar-nav .nav-item.show .nav-link.dropdown-toggle:after {
      transform: scale(1.5) rotate(-227deg) translate(6px, -2px);
    }
  }

  @media screen and (min-width: 576px) {
    header .navbar .navbar-nav .nav-item .dropdown-menu {
      width: 540px;
    }
  }

  @media screen and (min-width: 768px) {
    header .navbar .tablet-search-form-wrap .tablet-search-toggle {
      display: block;
    }

    header .navbar .navbar-collapse {
      border-top: unset;
      margin-top: 0;
      padding-top: 0;
    }

    header .navbar .navbar-nav {
      align-items: center;
    }

    header .navbar .navbar-nav .dropdown {
      position: unset;
    }

    header .navbar .navbar-nav .nav-item .dropdown-menu {
      width: 720px;
    }
  }

  @media screen and (min-width: 992px) {
    header .navbar .tablet-search-form-wrap .tablet-search-toggle {
      display: none;
    }

    header .navbar .navbar-nav .nav-item .dropdown-menu {
      width: 990px;
    }
  }

  @media screen and (min-width: 1200px) {
    header .navbar .navbar-nav .nav-item .dropdown-menu {
      width: 1200px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 360px) {
    header .free-trial-mobile .btn-white {
      padding: 9px 15px;
    }
  }

  body.admin-bar header {
    top: 32px;
  }

  @media screen and (max-width: 782px) {
    body.admin-bar header {
      top: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .mobile-menu-opened header {
      height: 560px;
      overflow-y: auto;
    }
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form fieldset:nth-child(3) {
    width: 100%;
    float: left;
    clear: both;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form fieldset.form-columns-2 .hs-form-field {
    width: 100% !important;
    float: 100% !important;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form .hs-button:hover {
    background-color: #55b8e3 !important;
    color: #fff;
    border: 1px solid #55b8e3 !important;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form input.hs-input.invalid.error {
    border: 1px solid red;
    margin-bottom: 8px;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form .hs-button {
    margin: auto !important;
    background-color: ${colors.oceanBlue};
    border-color: ${colors.oceanBlue} !important;
    padding: 0;
    font-size: 14px !important;
    width: 100% !important;
    max-width: 100px;
  }

  #hsForm_19fc9861-9bb0-4c56-beb5-519e2389f643.hs-form .hs-error-msgs {
    list-style: none;
    text-align: left;
  }

  body.single-resources.logged-in.admin-bar main {
    margin-top: 32px;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form fieldset:nth-child(3) {
    width: 100%;
    float: left;
    clear: both;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form fieldset.form-columns-2 .hs-form-field {
    width: 100% !important;
    float: 100% !important;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form .hs-button {
    margin: auto !important;
    background-color: ${colors.oceanBlue};
    border-color: ${colors.oceanBlue} !important;
    padding: 0;
    font-size: 14px !important;
    width: 100% !important;
    max-width: 100px;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form .hs-button:hover {
    background-color: #55b8e3 !important;
    color: #fff;
    border: 1px solid #55b8e3 !important;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form .hs-error-msgs {
    list-style: none;
    text-align: left;
  }

  #hsForm_bc36b0d2-ba27-4d74-808b-73fb3fec3383.hs-form input.hs-input.invalid.error {
    border: 1px solid red;
  }

  footer {
    background-color: #f2f2f2;
  }

  footer .logo {
    width: 123px;
  }

  footer .social-media-nav {
    width: 478px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  footer .social-media-nav svg {
    fill: #adb3bc;
    transition: 300ms ease all;
  }

  footer .social-media-nav .nav-link:hover svg path {
    fill: #2196c9;
  }

  #free_trial .form-wrap form input::-moz-placeholder,
  #free_trial .form-wrap form select::-moz-placeholder,
  #free_trial .form-wrap form textarea::-moz-placeholder,
  #get_pricing .form-wrap form input::-moz-placeholder,
  #get_pricing .form-wrap form select::-moz-placeholder,
  #get_pricing .form-wrap form textarea::-moz-placeholder,
  #request_demo .form-wrap form input::-moz-placeholder,
  #request_demo .form-wrap form select::-moz-placeholder,
  #request_demo .form-wrap form textarea::-moz-placeholder,
  .hs-form input::-moz-placeholder,
  .hs-form select::-moz-placeholder,
  .hs-form textarea::-moz-placeholder {
    color: #adb3bc;
  }

  #free_trial .form-wrap form input::placeholder,
  #free_trial .form-wrap form select::placeholder,
  #free_trial .form-wrap form textarea::placeholder,
  #get_pricing .form-wrap form input::placeholder,
  #get_pricing .form-wrap form select::placeholder,
  #get_pricing .form-wrap form textarea::placeholder,
  #request_demo .form-wrap form input::placeholder,
  #request_demo .form-wrap form select::placeholder,
  #request_demo .form-wrap form textarea::placeholder,
  .hs-form input::placeholder,
  .hs-form select::placeholder,
  .hs-form textarea::placeholder {
    color: #adb3bc;
  }

  #hsForm_8aa4ea91-216a-467a-b8bf-fdc43b2cf24f .hs-error-msgs {
    list-style: none;
  }

  .comparision-bottom-section {
    padding-bottom: 60px;
  }

  .modal-open,
  .modal-open .modal {
    padding-right: 0 !important;
  }

  .modal-open .modal::-webkit-scrollbar {
    width: 0;
  }

  .modal-open .modal::-webkit-scrollbar-track {
    background: 0 0;
  }

  .modal-open .modal::-webkit-scrollbar-thumb,
  .modal-open .modal::-webkit-scrollbar-thumb:hover {
    background: 0 0;
  }

/*
* Change cta-boxes background from dbe4ec to fff so that it matches the light-dark band pattern
*/

  .cta-boxes {
    background: #fff;
  }

  .cta-boxes .resource-block {
    position: relative;
    background: #fff;
    text-align: center;
    padding: 3rem 2rem;
    box-shadow: 0 8px 16px #253e5819;
    border-bottom: 5px solid #dce2e5;
    transition: 0.5s ease all;
  }

  .cta-boxes .resource-block h5 {
    font-size: 26px;
    color: #253e58;
    font-weight: 400;
    width: 90%;
    margin: 25px auto;
  }

  .cta-boxes .resource-block .btn {
    text-transform: uppercase;
  }

  .cta-boxes .resource-block.dark-blue:hover {
    border-bottom: 5px solid #325476;
  }

  .cta-boxes .resource-block.dark-blue svg {
    fill: #325476;
  }

  .cta-boxes .resource-block.blue:hover {
    border-bottom: 5px solid ${colors.oceanBlue};
  }

  .cta-boxes .resource-block.blue svg {
    fill: ${colors.oceanBlue};
  }

  .cta-boxes .resource-block.green:hover {
    border-bottom: 5px solid #0fac7c;
  }

  .cta-boxes .resource-block.green svg {
    fill: #0fac7c;
  }

  .cta-boxes .resource-block:hover {
    box-shadow: 0 8px 16px 4px #253e5833;
    transition: 0.5s ease all;
  }

  .cta-boxes .resource-block:hover .btn {
    color: #fff;
    background-color: rgba(21, 139, 194, 0.7);
    border-color: #fff;
    text-decoration: none;
    transition: 0.5s ease all;
  }

  @media only screen and (max-width: 991px) {
    .cta-boxes .resources-row {
      justify-content: center;
    }

    .cta-boxes .resource-block {
      margin-bottom: 15px;
    }
  }

  .comparision-bottom-section {
    background-color: #f9f9f9;
  }

  .comparision-bottom-section .box-wrap {
    display: flex;
    border: 1px solid #dfdfe0;
    padding: 35px;
    border-radius: 15px;
    box-shadow: 0 3px 15px #0000000f;
  }

  .comparision-bottom-section .box-wrap .text-wrap {
    margin-left: 25px;
  }

  .comparision-bottom-section .box-wrap .text-wrap h3 {
    font-size: 22px;
  }

  .comparision-bottom-section .box-wrap .text-wrap a {
    margin: 18px 18px 0 0;
  }

  .comparision-bottom-section .box-wrap .image-wrap {
    width: 85px;
  }

  #filtertypes .dropdown-item.all:before,
  .hs-form label {
    display: none;
  }

  .form-section .form-wrap {
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 8px 16px #253e5819;
    border: 1px solid #dce2e5;
    border-radius: 4px;
    text-align: center;
  }

  .form-section .form-wrap .check-col {
    margin-top: 10px;
  }

  .form-section .form-wrap .form-group {
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
  }

  .form-section .form-wrap .form-group input::-moz-placeholder {
    color: #8693a5;
    font-size: 18px;
    font-weight: 300;
  }

  .form-section .form-wrap .form-group input::placeholder {
    color: #8693a5;
    font-size: 18px;
    font-weight: 300;
  }

  .form-section .form-wrap .form-group .form-control {
    border: 1px solid #bec2c7;
    color: #8693a5;
    font-size: 18px;
    font-weight: 300;
  }

  .form-section .form-wrap .form-group label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #8693a5;
    font-size: 18px;
  }

  .form-section .form-wrap ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .form-section .form-wrap ul li {
    float: left;
    display: flex;
    margin-right: 40px;
  }

  .form-section .form-wrap .btn.btn-primary {
    margin-top: 40px;
    padding: 9px 40px;
  }

  .form-section .form-wrap input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: 25px;
    height: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    outline: 0;
    transition-duration: 0.3s;
    border: 1px solid #d4d8de;
    cursor: pointer;
    background: #fff;
    font-size: 20px;
    margin-right: 16px;
  }

  .form-section .form-wrap input:checked {
    background-color: #2196c9;
    /* background-image: url(../img/talk-to-expert/Check.svg); */
    background-repeat: no-repeat;
    background-position: center;
  }

  .form-section .form-wrap .form-check-input {
    width: auto !important;
    height: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    transition-duration: 0.3s;
    cursor: pointer;
    background: #f5fbff;
  }

  @media screen and (max-width: 991px) {
    .form-section .form-wrap {
      padding: 60px 40px;
    }
  }

  @media screen and (max-width: 767px) {
    .form-section .form-wrap .form-group label {
      font-size: 16px;
    }

    .form-section .form-wrap .form-group input::-moz-placeholder {
      font-size: 16px;
    }

    .form-section .form-wrap .form-group input::placeholder {
      font-size: 16px;
    }
  }

  #free_trial .form-wrap form input,
  #free_trial .form-wrap form select,
  #free_trial .form-wrap form textarea,
  #get_pricing .form-wrap form input,
  #get_pricing .form-wrap form select,
  #get_pricing .form-wrap form textarea,
  #request_demo .form-wrap form input,
  #request_demo .form-wrap form select,
  #request_demo .form-wrap form textarea {
    border: solid 1px #bec2c7;
    padding: 8px 12px;
    border-radius: 5px;
    width: 100%;
    float: left;
    color: #8693a5;
    margin-bottom: 30px;
    font-size: 1.25rem;
  }

  #free_trial .form-wrap form input:nth-child(1),
  #free_trial .form-wrap form input:nth-child(2),
  #free_trial .form-wrap form select:nth-child(1),
  #free_trial .form-wrap form select:nth-child(2),
  #free_trial .form-wrap form textarea:nth-child(1),
  #free_trial .form-wrap form textarea:nth-child(2),
  #get_pricing .form-wrap form input:nth-child(1),
  #get_pricing .form-wrap form input:nth-child(2),
  #get_pricing .form-wrap form select:nth-child(1),
  #get_pricing .form-wrap form select:nth-child(2),
  #get_pricing .form-wrap form textarea:nth-child(1),
  #get_pricing .form-wrap form textarea:nth-child(2),
  #request_demo .form-wrap form input:nth-child(1),
  #request_demo .form-wrap form input:nth-child(2),
  #request_demo .form-wrap form select:nth-child(1),
  #request_demo .form-wrap form select:nth-child(2),
  #request_demo .form-wrap form textarea:nth-child(1),
  #request_demo .form-wrap form textarea:nth-child(2),
  .hs-form input:nth-child(1),
  .hs-form input:nth-child(2),
  .hs-form select:nth-child(1),
  .hs-form select:nth-child(2),
  .hs-form textarea:nth-child(1),
  .hs-form textarea:nth-child(2) {
    width: 48%;
  }

  #free_trial .form-wrap form select,
  #get_pricing .form-wrap form select,
  #request_demo .form-wrap form select {
    background: 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.297' height='11.563' viewBox='0 0 20.297 11.563'%3E%3Cpath id='Path_105132' data-name='Path 105132' d='M628.129,1602.461l9.442,9.442,9.442-9.442' transform='translate(-627.422 -1601.754)' fill='none' stroke='%238693a5' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 20px;
  }

  #free_trial .form-wrap form input[type="checkbox"],
  #get_pricing .form-wrap form input[type="checkbox"],
  #request_demo .form-wrap form input[type="checkbox"] {
    margin-bottom: 20px;
  }

  #free_trial .form-wrap form textarea,
  #get_pricing .form-wrap form textarea,
  #request_demo .form-wrap form textarea {
    overflow: hidden;
    height: calc(1.5em + 0.75rem + 2px);
    resize: none !important;
  }

  #free_trial .form-wrap form button,
  #get_pricing .form-wrap form button,
  #request_demo .form-wrap form button {
    background-color: ${colors.oceanBlue};
    color: #fff !important;
    border: solid 1px ${colors.oceanBlue} !important;
    padding: 8px 44px;
    width: 100%;
    max-width: 130px;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
    margin: auto;
    display: block;
    text-align: center;
    float: none;
  }

  #free_trial .form-wrap form button:hover,
  #get_pricing .form-wrap form button:hover,
  #request_demo .form-wrap form button:hover,
  .hs-form button:hover {
    background-color: #55b8e3;
    color: #fff;
    border-color: transparent !important;
  }

  .hs-form label.hs-error-msg {
    display: block !important;
    font-size: 16px;
    color: #db6666;
    padding-left: 4px;
  }

  .hs-form .hs-error-msgs {
    display: block !important;
  }

  .hs-form .hs-error-msgs li {
    margin-top: -20px;
  }

  .hs-form .hs-fieldtype-checkbox label {
    display: block;
    font-size: 20px;
    color: #77797b;
    width: auto;
    text-align: left;
  }

  .hs-form .hs_product_interest span,
  .hs-form ul li.hs-form-checkbox span {
    font-size: 18px;
    color: #8693a5;
    font-weight: 300;
  }

  .hs-form ul li.hs-form-checkbox span {
    float: left;
  }

  .hs-form ul {
    margin: 0;
    padding: 0;
  }

  .hs-form ul li.hs-form-checkbox {
    list-style-type: none;
  }

  .hs-form ul li.hs-form-checkbox input.hs-input {
    min-width: 25px;
    width: auto !important;
    height: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    outline: 0;
    transition-duration: 0.3s;
    border: 1px solid #d4d8de;
    cursor: pointer;
    background: #fff;
    font-size: 20px;
  }

  .hs-form ul li.hs-form-checkbox input.hs-input:checked {
    background-color: #2196c9;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.291' height='13.447' viewBox='0 0 19.291 13.447'%3E%3Cpath id='Check' d='M-6114.864,9020.041l5.43,5.736,11.033-11.033' transform='translate(6116.278 -9013.33)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  }

  .hs-form ul li.hs-form-checkbox label {
    cursor: pointer;
    position: relative;
    font-size: 20px;
    color: #77797b;
    display: flex;
    width: 100%;
  }

  .hs-form .hs_product_interest ul li.hs-form-checkbox {
    width: 25%;
    min-width: inherit !important;
    margin: 0;
  }

  .hs-form .hs_product_interest ul li.hs-form-checkbox:last-child {
    width: 50%;
  }

  .hs-form fieldset {
    max-width: unset !important;
    position: relative;
  }

  .hs-form fieldset:nth-child(3) {
    width: 50%;
    float: left;
    clear: both;
  }

  .hs-form fieldset:nth-child(4) {
    width: 50%;
  }

  .hs-form input,
  .hs-form select,
  .hs-form textarea {
    border: 1px solid #bec2c7;
    padding: 8px 12px;
    border-radius: 5px;
    width: 100%;
    float: left;
    color: #8693a5;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
    background-color: #fff;
  }

  .hs-form input:nth-child(1),
  .hs-form select:nth-child(1),
  .hs-form textarea:nth-child(1) {
    margin-right: 4%;
  }

  .hs-form textarea {
    height: 105px;
  }

  .hs-form button {
    background-color: ${colors.oceanBlue};
    color: #fff !important;
    border: solid 1px ${colors.oceanBlue};
    padding: 8px 44px;
    border-radius: 5px;
  }

  .hs-form .hs-button,
  .hs-form .input .hs-input {
    width: 100% !important;
  }

  .hs-form .hs-button {
    background-color: #7c7c7c;
    color: #fff !important;
    border: solid 1px #7c7c7c !important;
    max-width: 130px;
    border-radius: 2px !important;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: uppercase;
    margin: auto;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-top: 37px;
    text-align: center;
    float: none !important;
  }

  .hs-form .hs-button:not([disabled]) {
    background-color: ${colors.oceanBlue};
    border: solid 1px ${colors.oceanBlue} !important;
    color: #fff !important;
  }

  .hs-form .hs-button:not([disabled]):hover {
    background-color: #55b8e3;
    color: #fff;
    border-color: transparent !important;
  }

  .hs-form select:after {
    content: "";
    width: 10px;
    height: 10px;
    border: 0;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg) translate(-3px, -2px);
    margin-left: 0.75rem;
    transition: all 300ms ease;
  }

  @media screen and (max-width: 991px) {
    .hs-form
      .hs-form-695fb5ca-598e-4f7a-80d6-10d235d17ab7_57975a90-b52f-49fc-b216-25cc78fc9acc
      fieldset.form-columns-2
      .hs-form-field {
      width: 100%;
      float: none;
    }

    .hs-form .hs_product_interest ul li.hs-form-checkbox:last-child {
      width: 100%;
    }

    .hs-form fieldset:nth-child(3) {
      width: 100%;
      float: none;
    }

    .hs-form fieldset:nth-child(4) {
      width: 100%;
    }

    .hs-form .hs_product_interest ul li.hs-form-checkbox {
      width: 100%;
    }
  }

  .form-popup .modal-content {
    background-color: transparent;
    border: 0;
  }

  .form-popup .form-section .form-wrap {
    padding-top: 10px;
  }

  .form-popup .modal-header {
    border: 0;
  }

  @media (min-width: 1199px) {
    .form-popup .modal-dialog {
      max-width: 1199px;
    }

    .form-popup .modal-dialog .form-section {
      padding: 0;
    }
  }

  @media (min-width: 992px) {
    .form-popup .modal-dialog {
      max-width: 1199px;
    }
  }

  @media (min-width: 768px) {
    .hs_product_interest ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }

    .hs_product_interest ul li.hs-form-checkbox {
      width: 50%;
      min-width: 240px !important;
    }
  }

  #subscribe-modal .hs-form ul li.hs-form-checkbox input.hs-input {
    width: 25px !important;
  }

  #filtertypes .dropdown-item:before {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #000;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 7px;
  }

  #filtertypes .dropdown-item.docs:before {
    background-color: #55b8e3;
  }

  #filtertypes .dropdown-item.hooks:before {
    background-color: #325476;
  }

  #filtertypes .dropdown-item.audios:before,
  #filtertypes .dropdown-item.demo:before,
  #filtertypes .dropdown-item.videos:before {
    background-color: #0fac7c;
  }

  .search-bar {
    margin-left: auto;
    max-width: 375px;
    width: 100%;
  }

  .search-bar form {
    display: flex;
    position: relative;
  }

  .search-bar input {
    width: 100%;
    padding: 0.3rem 1rem;
    font-size: 18px;
    border: 1px solid #d4d8de;
    border-radius: 4px;
    outline: 0;
  }

  .search-bar input:placeholder {
    opacity: 0.5;
  }

  .search-bar button {
    border: 0;
    position: absolute;
    background: 0 0;
    right: 0.5rem;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 100%;
  }

  .search-bar button svg {
    width: 22px;
    height: 22px;
    fill: #35393b;
  }

  @media (max-width: 991px) {
    .search-bar {
      max-width: 220px;
    }
  }

  @media (max-width: 767px) {
    .search-bar {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .resources-nav {
    overflow: hidden;
    padding-top: 25px;
  }

  .resources-nav ul {
    margin: 0;
    display: flex;
  }

  .resources-nav ul li {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;
    color: #8693a5;
    font-size: 24px;
  }

  .resources-nav ul li a {
    color: #8693a5;
    text-decoration: none;
  }

  .resources-nav ul li.active {
    border-color: ${colors.oceanBlue};
    color: ${colors.oceanBlue};
  }

  .tab-section {
    background: #f8fafb;
    padding-bottom: 3rem;
  }

  .tab-section .container {
    text-align: center;
  }

  .tab-section h2 {
    font-size: 36px;
    color: #253e58;
  }

  .tab-section p.p-lead {
    font-size: 22px;
    width: 69%;
    margin: auto auto 30px;
  }

  .resources-nav ul {
    list-style: none;
    padding: 0;
  }

  .article .new-author-container {
    margin-top: 20px;
  }

  .article .new-author-top-container {
    display: flex;
    font-size: 22px;
    color: #adb3bc;
    flex-wrap: wrap;
  }

  .article.resource-inside .content {
    padding: 2rem;
  }

  .article .content img {
    height: auto;
  }

  .article .content .container {
    display: flex;
  }

  @media (max-width: 991px) {
    .article .content .container {
      flex-direction: column;
    }
  }

  .contact {
    padding-bottom: 3em;
  }

  .contact .contact-info .container {
    display: flex;
  }

  .contact .contact-info .container .text-block {
    padding: 0;
  }

  .contact .contact-info .container .text-wrap {
    margin: auto;
  }

  .contact .contact-info .container .text-wrap h1 {
    font-size: 58px;
    font-weight: 800;
    margin: 0.5em 0;
    color: #253e58;
  }

  .contact .contact-info .container .text-wrap p {
    font-size: 26px;
    width: 85%;
    line-height: normal;
    color: #35393b;
  }

  .contact .contact-info .container .map-block .map-pin {
    position: absolute;
    top: 200px;
    left: 275px;
  }

  .contact .contact-info .container .map-block .address {
    position: absolute;
    top: 180px;
    left: 320px;
    background: #fff;
    padding: 15px;
    border: 1px solid #f0f1f2;
    font-size: 16px;
    color: #35393b;
  }

  .contact .contact-info .container .map-block .address:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 24px solid #fff;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
    border-bottom: 20px solid transparent;
    left: -5px;
    bottom: 24px;
    transform: rotate(-45deg);
  }

  .form-section .form-wrap {
    padding: 30px 40px 40px;
  }

  .form-section .form-wrap .modal-header {
    padding: 20px 0 10px;
  }

  .form-section .form-wrap .info {
    margin-bottom: 20px;
  }

  @media (min-width: 1199px) {
    .form-popup .modal-dialog {
      max-width: 1000px;
    }
  }

  @media screen and (max-width: 767px) {
    body.single-resources.logged-in.admin-bar main {
      margin-top: 0;
    }
  }
`;
