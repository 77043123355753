import { Button, Container, Nav, Row } from "react-bootstrap";

export const OverviewDropdownContent = () => {
  return (
    <Container>
      <Row className="nav-row">
        <div className="col-md-5 white-col nav-col">
          <div className="left-sub-nav d-none d-md-block">
            <h4>Why Gloo Platform</h4>{" "}
            <p>
              Get started today with API Infrastructure from the Edge to Service Mesh, built on Istio and Envoy Proxy,
              built for Kubernetes and cloud
            </p>
            {/* Navigation Links Start */}
            {/* Navigation Links End */}
            {/* Navigation Buttons Start */}
            <Button variant="primary" href="https://solo.io/products/gloo-platform" target="_self">
              LEARN MORE
            </Button>{" "}
            {/* Navigation Buttons End */}
            {/* Navigation Buttons Popup Start */}
            {/* Navigation Buttons Popup End */}
          </div>
        </div>
        {/* /.col-md-4  */}
        <div className="col-md-4 grey-col nav-col">
          <div className="center-sub-nav">
            <ul className="nav flex-column">
              <Nav.Item>
                <h5>Use Cases</h5>
                <ul className="nav flex-column">
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/topics/service-mesh/" target="_self">
                      Istio Service Mesh Management
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/topics/kubernetes-api-gateway/" target="_self">
                      Kubernetes API Gateway
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/zero-trust/" target="_self">
                      Zero Trust Security
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/topics/api-gateway/moving-to-a-modern-api-gateway/" target="_self">
                      Moving to a Modern API Gateway
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </Nav.Item>
            </ul>
          </div>
        </div>
        <div className="col-md-3 white-col nav-col">
          <div className="right-sub-nav">
            {/* Navigation start */}
            {/* Navigation End */}

            {/* Content and  Navigation Start */}
            <ul className="nav flex-column">
              <Nav.Item>
                <h5>Industry and Open Source</h5>
                <ul className="nav flex-column">
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/topics/istio/" target="_self">
                      What is Istio?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/products/ambient-mesh/" target="_self">
                      Ambient Mesh
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/topics/cilium/" target="_self">
                      Cilium
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/products/web-assembly/" target="_self">
                      Web Assembly (Wasm)
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </Nav.Item>
            </ul>
            {/* Content and  Navigation End */}

            {/* Text, Image and Content Start */}

            {/* Text, Image and Content End */}
          </div>
        </div>
      </Row>
    </Container>
  );
};
