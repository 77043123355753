import React from "react";
import ReactDOM from "react-dom";
import "./fontFace.css";
import DocsHub from "./DocsHub";

ReactDOM.render(
  <React.StrictMode>
    <DocsHub />
  </React.StrictMode>,
  document.getElementById("root")
);
